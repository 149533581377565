import { IWidgetProps } from '../components/SearchResults/Widget/components/Widget/Widget.types';

const MAX_COLLECTIONS_TO_DISPLAY = 10;

export const getVisibleCollections = (
  collections: IWidgetProps['productFacets']['collections'],
  selected: string[],
) => {
  const selectedCollections = collections
    .filter((collection) => selected.includes(collection.value))
    .map((collection) => collection.value)
    .slice(0, MAX_COLLECTIONS_TO_DISPLAY);

  const notSelectedCollections = collections
    .filter((collection) => !selectedCollections.includes(collection.value))
    .map((collection) => collection.value)
    .slice(0, MAX_COLLECTIONS_TO_DISPLAY - selectedCollections.length);

  return collections.filter(
    (collection) =>
      selectedCollections.includes(collection.value) ||
      notSelectedCollections.includes(collection.value),
  );
};
