import {
  SearchDocumentType,
  ISearchRequestPageNumber,
  ISearchRequest,
} from '@wix/client-search-sdk';
import { ILocation } from '@wix/native-components-infra/dist/src/types/types';

export enum SearchQueryParams {
  Query = 'q',
  DocumentType = 'qc',
  Page = 'page',
  SortBy = 'sort',
  Price = 'price',
  Collections = 'collections',
}

export interface ILocationSearchRequest {
  query: string;
  documentType?: SearchDocumentType;
  page?: ISearchRequestPageNumber;
  minPrice?: number;
  maxPrice?: number;
  collections?: string[];
}

export type IEncodedLocationSearchRequest = {
  [key in SearchQueryParams]?: string;
};

export type IDecodeSearchParams = (
  location: ILocation,
) => ILocationSearchRequest;

export type IEncodeSearchParams = (
  searchRequest: ILocationSearchRequest,
  location?: ILocation,
) => string;

export interface ISearchLocation {
  decodeParams: IDecodeSearchParams;
  encodeParams: IEncodeSearchParams;

  navigateToSearchResults(searchRequest: ILocationSearchRequest): Promise<void>;

  getSearchResultsRelativeUrl(): Promise<string>;
  getSearchResultsAbsoluteUrl(): Promise<string>;

  toSDKSearchRequest(
    searchRequest: ILocationSearchRequest,
    pageSize: number,
    showProductFacets: boolean,
  ): ISearchRequest;

  toLocationSearchRequest(
    searchRequest: ISearchRequest,
  ): ILocationSearchRequest;

  buildSearchResultsUrl(
    searchResultsAbsoluteUrl: string,
    locationSearchRequest: ILocationSearchRequest,
  ): string;
}
