import {
  ISearchRequest,
  ISearchRequestFilter,
  ISearchResponse,
  ApiTypes,
} from '@wix/client-search-sdk';
import { SearchRequestStatus } from '../../types/types';
import { SearchParams } from '../search';

export interface IProductFacetsFilter {
  minPrice?: number;
  maxPrice?: number;
  collections?: string[];
}

export interface IProductFacetsCollection {
  value: string;
  count: number;
}

export interface IProductFacetsState {
  enabled: boolean;
  filter: IProductFacetsFilter;
  minPrice: number;
  maxPrice: number;
  collections: IProductFacetsCollection[];
}

const MAX_COLLECTIONS_TO_LOAD = 999;
const FACET_COLLECTIONS = 'collections';
const FACET_PRICE = 'discountedPriceNumeric';

export const convertProductFacetsFilterToRequestParams = ({
  minPrice,
  maxPrice,
  collections = [],
}: IProductFacetsFilter): Partial<ISearchRequest> => {
  const hasMinPrice = minPrice !== undefined;
  const hasMaxPrice = maxPrice !== undefined;

  const priceFilter =
    hasMinPrice || hasMaxPrice
      ? {
          discountedPriceNumeric: {
            ...(hasMinPrice && { $gte: minPrice }),
            ...(hasMaxPrice && { $lte: maxPrice }),
          },
        }
      : undefined;

  const collectionsFilter = collections.length
    ? {
        collections: {
          $any: collections,
        },
      }
    : undefined;

  const filter =
    priceFilter || collectionsFilter
      ? {
          $and: [priceFilter, collectionsFilter].filter(
            (value) => !!value,
          ) as ISearchRequestFilter[],
        }
      : undefined;

  return {
    facets: {
      clauses: [
        {
          term: {
            name: FACET_COLLECTIONS,
            limit: MAX_COLLECTIONS_TO_LOAD,
          },
        },
        {
          aggregation: {
            name: FACET_PRICE,
            aggregation: 'MIN_MAX',
          },
        },
      ],
    },
    filter,
  };
};

export const convertProductFacetsRequestParamsToFilter = ({
  filter,
}: Pick<ISearchRequest, 'filter'>): IProductFacetsFilter => {
  if (!filter || !filter.$and) {
    return {};
  }

  const priceFilter = filter.$and.find(
    ({ discountedPriceNumeric }) => !!discountedPriceNumeric,
  )?.discountedPriceNumeric;

  const [minPrice, maxPrice] =
    priceFilter && !Array.isArray(priceFilter)
      ? [priceFilter.$gte, priceFilter.$lte]
      : [];

  const collectionsFilter = filter.$and.find(({ collections }) => !!collections)
    ?.collections;

  const collections = !Array.isArray(collectionsFilter)
    ? collectionsFilter?.$any ?? []
    : [];

  return {
    minPrice,
    maxPrice,
    collections,
  };
};

export const extractProductFacetsFromSearchResponse = ({
  facets,
}: Pick<ISearchResponse, 'facets'>): Pick<
  IProductFacetsState,
  'minPrice' | 'maxPrice' | 'collections'
> => {
  const collections = (
    facets.find(({ terms }) => !!terms && terms.facet === FACET_COLLECTIONS)
      ?.terms?.facets ?? []
  ).map(({ facetValue, count }) => ({ value: facetValue, count }));

  const minPrice =
    facets.find(
      ({ minAggregation }) =>
        !!minAggregation && minAggregation.facet === FACET_PRICE,
    )?.minAggregation?.minValue ?? 0;

  const maxPrice =
    facets.find(
      ({ maxAggregation }) =>
        !!maxAggregation && maxAggregation.facet === FACET_PRICE,
    )?.maxAggregation?.maxValue ?? 0;

  return {
    minPrice,
    maxPrice,
    collections,
  };
};

export const convertProductFacetsToFacetsResponse = ({
  minPrice,
  maxPrice,
  collections = [],
}: Pick<
  IProductFacetsState,
  'minPrice' | 'maxPrice' | 'collections'
>): ApiTypes.ISearchResponseFacet[] => {
  const collectionsFacet: ApiTypes.ISearchResponseFacet = {
    terms: {
      facet: FACET_COLLECTIONS,
      facets: collections.map((collection) => ({
        facetValue: collection.value,
        count: collection.count,
      })),
    },
  };

  const minPriceFacet: ApiTypes.ISearchResponseFacet = {
    minAggregation: {
      facet: FACET_PRICE,
      minValue: minPrice,
    },
  };

  const maxPriceFacet: ApiTypes.ISearchResponseFacet = {
    maxAggregation: {
      facet: FACET_PRICE,
      maxValue: maxPrice,
    },
  };

  return [collectionsFacet, minPriceFacet, maxPriceFacet];
};

export const isCollectionsSelected = (filter: IProductFacetsFilter) => {
  return (filter.collections ?? []).length > 0;
};

export const isMinPriceSelected = (filter: IProductFacetsFilter) => {
  return Number.isFinite(filter.minPrice);
};

export const isMaxPriceSelected = (filter: IProductFacetsFilter) => {
  return Number.isFinite(filter.maxPrice);
};

export const isCollectionsSelectionChanged = (
  { collections: currentCollections = [] }: IProductFacetsFilter,
  { collections: previousCollections = [] }: IProductFacetsFilter,
) => {
  return (
    currentCollections.length !== previousCollections.length ||
    !currentCollections.every((collection) =>
      previousCollections.includes(collection),
    )
  );
};

export const isPriceSelectionChanged = (
  currentFilter: IProductFacetsFilter,
  previousFilter: IProductFacetsFilter,
) => {
  return (
    currentFilter.minPrice !== previousFilter.minPrice ||
    currentFilter.maxPrice !== previousFilter.maxPrice
  );
};

export const hasAnySelectedProductFacets = (filter: IProductFacetsFilter) =>
  isCollectionsSelected(filter) ||
  isMinPriceSelected(filter) ||
  isMaxPriceSelected(filter);

export const shouldRequestFacet = (
  {
    searchRequest,
    previousSearchRequest,
    previousSearchRequestStatus,
  }: Pick<
    SearchParams,
    'searchRequest' | 'previousSearchRequest' | 'previousSearchRequestStatus'
  >,
  dependencies: ((
    currentRequest: IProductFacetsFilter,
    previousRequest: IProductFacetsFilter,
  ) => boolean)[],
) => {
  if (previousSearchRequestStatus === SearchRequestStatus.Initial) {
    return true;
  }

  const currentFilter = convertProductFacetsRequestParamsToFilter(
    searchRequest,
  );
  const previousFilter = convertProductFacetsRequestParamsToFilter(
    previousSearchRequest,
  );

  return dependencies.some((isFacetChanged) =>
    isFacetChanged(currentFilter, previousFilter),
  );
};

export const mergeFacets = (
  priceFacetsResponse: ISearchResponse,
  collectionsFacetsResponse: ISearchResponse,
) => {
  const collectionsFacets = extractProductFacetsFromSearchResponse(
    collectionsFacetsResponse,
  );

  const priceFacets = extractProductFacetsFromSearchResponse(
    priceFacetsResponse,
  );

  return convertProductFacetsToFacetsResponse({
    collections: collectionsFacets.collections,
    minPrice: priceFacets.minPrice,
    maxPrice: priceFacets.maxPrice,
  });
};
